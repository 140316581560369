import { useState, useEffect } from "preact/hooks";
import { Signal } from "@preact/signals";
import { isAuthenticatedSignal } from "./isAuthenticatedSignalPool.ts";
import LoginModal from "./LoginModal.tsx";
import AuthButtonGroup from "./AuthButtonGroup.tsx";
import TokenMonitorModal from "./TokenMonitorModal.tsx";

const OAuthSwitcherModal = ({ statusMessage }: { statusMessage: Signal<string> }) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isTokenMonitorOpen, setIsTokenMonitorOpen] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [idToken, setIdToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null); // Neuer State für den Refresh Token
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const fetchTokensForMonitor = async () => {
    try {
      const response = await fetch("/api/oauth_checkTokens", {
        method: "GET",
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.accessToken);
        setIdToken(data.idToken);
        setRefreshToken(data.refreshToken); // Refresh Token setzen
      } else {
        setAccessToken(null);
        setIdToken(null);
        setRefreshToken(null); // Refresh Token zurücksetzen
      }
    } catch (error) {
      console.error("Error fetching tokens for monitor:", error);
      setAccessToken(null);
      setIdToken(null);
      setRefreshToken(null);
    }
  };

  const checkToken = async () => {
    try {
      const response = await fetch("/api/oauth_checkTokens", {
        method: "GET",
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        isAuthenticatedSignal.value = data.isAuthenticated;
      } else {
        isAuthenticatedSignal.value = false;
      }
    } catch (error) {
      console.error("Error checking token:", error);
      isAuthenticatedSignal.value = false;
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleLogin = async () => {
    statusMessage.value = "OAuth CheckIn ...";
    setError(null);

    try {
      const response = await fetch("/api/oauth_checkin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Login failed.");
      }

      const data = await response.json();
      setIsLoginModalOpen(false);
      statusMessage.value = "Login successful!";
      
      // Set the shared signal to true on successful login
      isAuthenticatedSignal.value = true;
    } catch (err: any) {
      setError(err.message || "Login failed.");
      statusMessage.value = "Login failed. Please check your credentials.";
    }
  };

  const handleLogout = async () => {
    statusMessage.value = "OAuth CheckOut ...";
    try {
      const response = await fetch("/api/oauth_checkout", {
        method: "POST",
        credentials: "include", // Sends HttpOnly Cookies to remove them
      });

      if (!response.ok) {
        throw new Error("Logout failed");
      }

      statusMessage.value = "Logout successful!";
      
      // Set the shared signal to false on successful logout
      isAuthenticatedSignal.value = false;
    } catch (error) {
      console.error("Logout failed", error);
      statusMessage.value = "Logout failed";
    }
  };

  return (
    <div class="flex items-center justify-center space-x-0">
      <AuthButtonGroup
        isAuthenticated={isAuthenticatedSignal.value}
        toggleModal={() => setIsLoginModalOpen(true)}
        handleLogout={handleLogout}
        fetchTokensForMonitor={fetchTokensForMonitor}
        setIsTokenMonitorOpen={setIsTokenMonitorOpen}
      />

      {isLoginModalOpen && (
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          onLogin={handleLogin}
          username={username}
          password={password}
          setUsername={setUsername}
          setPassword={setPassword}
          error={error}
        />
      )}

      <TokenMonitorModal
        isOpen={isTokenMonitorOpen}
        onClose={() => setIsTokenMonitorOpen(false)}
        fetchTokensForMonitor={fetchTokensForMonitor}
        accessToken={accessToken}
        idToken={idToken}
        refreshToken={refreshToken} // Refresh Token übergeben
      />
    </div>
  );
};

export default OAuthSwitcherModal;
